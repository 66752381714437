/* global correction on icons to high */
.anticon svg {
	display: block !important;
}

.site-layout .site-layout-background {
	background: #fff;
	height: 50px;
}

.ant-menu-vertical .ant-menu-item {
	margin-top: 0;
}

.ant-menu-item {
	padding-left: 30px !important;
}

.ant-menu-submenu-title {
	padding-left: 30px !important;
}

.sider-fx {
	overflow: 'auto';
	height: '100vh';
	position: 'fixed';
	left: 0;
}

.menu-container {
	height: calc(100% - 250px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ant-menu-item-selected {
	color: #fff !important;
}

.circle {
	border-radius: 50%;
	width: 9px;
	height: 9px;
	background: transparent;
	border: 2px solid #1890ff;
	margin-right: 14px;
}

.active-link-circle {
	background: #1890ff;
}

.ant-menu-title-content {
	margin-left: 20px;
	display: flex;
	align-items: center;
}

.action-link {
	padding-top: 40px !important;
	border-top: 1px solid #e3e3e3;
	padding-bottom: 40px !important;
}

.logo-collapsed {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 29px;
	height: 80px;
	justify-content: space-between;
	margin-bottom: 20px;
}

.sidebar-logo {
	display: flex;
	align-items: center;
	margin-top: 20px;
	padding-left: 30px;
	justify-content: space-between;
	margin-bottom: 30px;
	width: 306px;
}

.sidebar-logo::after {
	content: '';
}

.ant-menu-submenu-inline .ant-menu-item {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.ant-layout-sider-children {
	width: inherit;
	position: fixed;
}

.header-cnt {
	display: flex;
	align-items: center;
	justify-content: space-between !important;
	height: inherit;
}

.header {
	width: 100%;
	top: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ant-menu-sub .ant-menu-item-selected {
	background: none !important;
	border-right-style: none !important;
}

.ant-menu-item-selected > .demo a {
	color: #fff !important;
}

.header-user-menu {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ant-avatar-square {
	border-radius: 5px !important;
}

.medium {
	font-weight: 500;
}

.user-menu-dropdown {
	margin-left: 10px;
}

.ml-1 {
	margin-left: 5px;
}

.ml-2 {
	margin-left: 10px;
}

.ml-3 {
	margin-left: 15px;
}

.ml-4 {
	margin-left: 20px;
}

.mr-3 {
	margin-right: 15px;
}

.user {
	display: flex;
	align-items: center;
}

.user-action-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 75px;
	margin-right: 20px;
}

.ant-badge svg {
	min-height: 26px;
}

.ant-layout-header {
	border-bottom: 1px solid #e3e3e3;
}

@media (max-width: 1440px) {
	.ant-layout-header {
		border-bottom: 1px solid #e3e3e3;
		padding: 3px 3px 0 0 !important;
	}
}

.ant-menu-inline-collapsed-tooltip {
	display: none !important;
}

.ant-layout {
	/*    background: #FCFBF7 !important; */
	background: #f2f2f2 !important;
}

.ant-card {
	width: 100%;
	border-radius: 8px !important;
}

.search-container > .ant-input-affix-wrapper-lg {
	padding: 0 !important;
}

.overlay-container {
	height: 65px;
	min-width: 200px;
	background-color: #fff;
	display: flex;
	align-items: center;
	border-radius: 8px;
	margin-top: 20px;
	box-shadow: 3px 3px 20px rgba(30, 64, 106, 0.1);
}

.overlay-wrapper {
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.ant-card-head {
	border: unset !important;
}

.ant-card-head-wrapper {
	min-height: 65px; /* for common height if no buttons are inside */
	align-items: flex-end !important;
	justify-content: space-between !important;
}

/* making selectors look like lables in read only case */
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: none !important;
	background-color: transparent !important;
	color: #000 !important;
}

.ant-page-header {
	display: block;
	padding: 10px 20px 10px 20px !important;
	background: white;
	border-bottom: 1px solid #e3e3e3;
}

.ant-table-sticky-holder {
	position: -webkit-sticky;
	position: sticky;
	z-index: calc(2 + 1);
	top: -30px !important;
	background: #fff;
}

.ant-select-disabled .ant-select-arrow {
	display: none;
}

/* weird antD behaviour of adding shadow to menu text */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	text-shadow: none !important;
}

.ant-page-header-content .ant-statistic-title {
	font-size: 14px !important;
}

.ant-page-header-content .ant-statistic-content {
	font-size: 16px !important;
}

.ant-card-body {
	padding: 16px 20px;
}

.ant-table-tbody .ant-statistic-content-value {
	font-size: 14px !important;
}

.ant-table-column-title {
	user-select: none;
}

/* fixing antd buttons glitch in card header */
.ant-card-extra .ant-btn {
	height: 32px !important;
}
.ant-card-extra {
	display: flex;
}
.ant-table-wrapper {
	border: 1px solid #f0f0f0;
	border-radius: 3px;
}

.ant-btn.ant-btn-background-ghost {
	box-shadow: none;
}
